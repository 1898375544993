(function($) {
	// Top Page Header background color
  var topheader = $('#home .l-header');
  $(window).scroll(function () {
    if ( $(this).scrollTop() > 200 ) {
      topheader.addClass('nonbg');
    } else {
    	topheader.removeClass('nonbg');
    }
  });

	// Navigation
	var html = $('html'),
	body = $('body'),
	header = $('#js-head'),
	nav = $('.c-gnav__wrap');

	$('#js-btn').on('click', function() {
		html.toggleClass('fixed');
		body.toggleClass('fixed');
		header.toggleClass('open');
		nav.toggleClass('open');
	});
	$('#js-nav a').on('click', function() {
		html.toggleClass('fixed');
		body.toggleClass('fixed');
		header.toggleClass('open');
		nav.toggleClass('open');
	});
	$('.c-gnav__wrap #js-nav__contact a').on('click', function() {
		html.toggleClass('fixed');
		body.toggleClass('fixed');
		header.toggleClass('open');
		nav.toggleClass('open');
	});
	$('.c-gnav__wrap .c-companyinfo a.google').on('click', function() {
		html.removeClass('fixed');
		body.removeClass('fixed');
		header.removeClass('open');
		nav.removeClass('open');
	});

	// Header fixed
	var startPos = 0,
		winScrollTop = 0,
		scrollDistance = 100; $(window).on('scroll', function() {
		winScrollTop = $(this).scrollTop();
		if (winScrollTop >= startPos) {
			if (winScrollTop >= scrollDistance) {
				$('#js-head').addClass('hide');
				$('#home #js-head').removeClass('on');
			}
		} else {
			if (winScrollTop == 0) {
				$('#home #js-head').removeClass('on');
			} else {
				$('#js-head').removeClass('hide');
				$('#home #js-head').addClass('on');
			}
		}
		startPos = winScrollTop;
	});

	// Current Page
	$('.c-gnav a').each(function() {
		var $href = $(this).attr('href'),
			$mn = $('.l-header__nav a');
		if (location.href.match($href)) {
			$mn.removeClass('active');
			$(this).addClass('active');
		} else {
			$(this).removeClass('active');
		}
	});

	$('.l-header__nav a').each(function() {
		var $href2 = $(this).attr('href'),
			$mn = $('.l-header__nav a');
		if (location.href.match($href2)) {
			$mn.removeClass('active');
			$(this).addClass('active');
		} else {
			$(this).removeClass('active');
		}
	});

	$('a[href^="#"]').each(function() {
		if (this.href == location.href) {
			$(this).parent().addClass('active');
		} else {
			$(this).parent().removeClass('active');
		}
	});

	$(window).on('load', function() {
		var $hmn1 = $('#information .l-header__nav a'),
			$hmn2 = $('#privacy .l-header__nav a');
		if (document.URL.match('information')) {
			$hmn1.removeClass('active');
		}
		if (document.URL.match('privacy')) {
			$hmn2.removeClass('active');
		}
	});

	// Smooth Scroll
	var headerHeight = $('.l-header').outerHeight(),
		speed = 500,
		urlHash = location.hash;
	if (urlHash) {
		$('body,html').stop().scrollTop(0);
		setTimeout(function() {
			var target = $(urlHash),
					position = target.offset().top - headerHeight - 100;
			$('body,html').stop().animate({
				scrollTop: position
			}, speed, "swing");
			return false;
		}, 100);
	};

	$('a[href^="#"]').click(function() {
		var headerHeight = $('.l-header').outerHeight(),
			href = $(this).attr("href"),
			target = $(href),
			position = target.offset().top - headerHeight;
		$('body,html').stop().animate({
			scrollTop: position
		}, speed, "swing");
		return false;
	});

	// Page Top
	var showFlag = false,
		topBtn = $('.c-pagetop'); topBtn.click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});

	// Next Section
	var idx = -1;
	var sectionPos = 0;
	var scrollTop = 0;
	var targetTop = 0; $(function() {
		$(window).on("load scroll", function(e) {
			$("section").each(function(index, ele) {
				sectionPos = $(ele).offset().top;
				scrollTop = $(window).scrollTop();
				idx = -1;
				if (sectionPos > scrollTop) {
					idx = index;
					return false;
				}
			});
		});
		$("#js-scroll").on("click", function(e) {
			if ($("section").eq(idx).is("*") && idx >= 0) {
				targetTop = $("section").eq(idx).offset().top;
				$("html, body").animate({ scrollTop: targetTop }, "normal");
			}
			return false;
		});
	});

	// Overlay & Modal
	$(function(){
		var winScrollTop;
		$('.js-modal-open').each(function(){
			$(this).on('click',function(){
				winScrollTop = $(window).scrollTop();
				var target = $(this).data('target'),
						modal = document.getElementById(target);
						slider = $('.slider');
				$(modal).fadeIn();
				slider.slick('setPosition'); 
				return false;
			});
		});
		$('.js-modal-close').on('click',function(){
			$('.js-modal').fadeOut();
			$('body,html').stop().animate({scrollTop:winScrollTop}, 100);
			return false;
		});
	});

	// Open/Close Panel
	$(function () {
		$(".c-panel dd").css("display", "none");
		$(".c-panel dt").click(function () {
			$(this).toggleClass("open").next().slideToggle(300);
		});
	});

	// Mouse over Image
	$('a img').hover(function() {
		$(this).attr('src', $(this).attr('src').replace('_off', '_on'));
	}, function() {
		if (!$(this).hasClass('currentPage')) {
			$(this).attr('src', $(this).attr('src').replace('_on', '_off'));
		}
	});

	// disabled Links
	$('a.js-disable').click(function() {
		return false;
	});

	// touchmove handler
	var linkTouchStart = function() {
		thisAnchor = $(this);
		touchPos = thisAnchor.offset().top;
		moveCheck = function() {
			nowPos = thisAnchor.offset().top;
			if (touchPos == nowPos) {
				thisAnchor.addClass("hover");
			}
		}
		setTimeout(moveCheck, 100);
	}
	var linkTouchEnd = function() {
		thisAnchor = $(this);
		hoverRemove = function() {
			thisAnchor.removeClass("hover");
		}
		setTimeout(hoverRemove, 500);
	}
	$(document).on('touchstart mousedown', 'a', linkTouchStart); $(document).on('touchend mouseup', 'a', linkTouchEnd);


})(jQuery);